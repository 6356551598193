import React from "react";
import { camelizeKeys } from "humps";
import { PAGES } from "@gonoodle/gn-universe-analytics-schema/src/constants";
import { Newsletter, Alert } from "@gonoodle/gn-universe-ui";
import { useRouter } from "next/router";
import { QueryClient, dehydrate } from "@tanstack/react-query";

import api from "../api";
import { useLayoutQuery } from "../hooks";
import Layout from "../components/Layout";
import { QUERY_KEYS, ROUTE_PERMISSIONS, MUTATION_KEYS } from "../constants";
import { withAuthorization } from "../containers";
import { useLogEvent } from "../contexts/Analytics";
import { useAppConfig } from "../contexts/appConfig";
import { useUser } from "../contexts/user";
import { getUtmParamsFromQueryParams } from "../utils/analytics";
import LayoutRenderer, {
  prefetchLayout,
  FETCHERS as LAYOUT_FETCHERS,
} from "../components/LayoutRenderer";

const Home = () => {
  const router = useRouter();
  const { customHomePageLayoutId: layoutId } = camelizeKeys(router.query);
  const { user, updateUser } = useUser();
  const { appConfig, setLogoutToastVisibility } = useAppConfig();
  const alert = Alert.useAlert();

  React.useEffect(() => {
    if (appConfig.toasts.logout) {
      alert.publish("You've been successfully logged out");
      setLogoutToastVisibility(false);
    }
  }, [alert, setLogoutToastVisibility, appConfig.toasts.logout]);

  const { layout, isLayoutLoading, layoutError } = useLayoutQuery(layoutId);

  const { logEvent: logNewsletterSignUpEvent } = useLogEvent({
    event: "Newsletter Sign Up",
    properties: {
      ...getUtmParamsFromQueryParams(router.query),
    },
    options: {
      enabled: false,
      referrer: {
        sourcePage: PAGES.HOME,
        sourcePageType: PAGES.HOME,
      },
    },
  });

  if (layoutError?.response?.status === 404) {
    return <p className="text-center pt-6">Layout not found</p>;
  }

  if (layoutError?.response?.status === 403) {
    return <p className="text-center pt-6">Forbidden access</p>;
  }

  if (layoutError) {
    alert.publish("Something went wrong. Please reload page");
    return null;
  }

  if (layout === null) {
    return <p className="text-center pt-6">Empty layout</p>;
  }

  if (isLayoutLoading) {
    return null;
  }

  const handleNewsletterSignup = (userInfo) => {
    updateUser(userInfo);
    logNewsletterSignUpEvent();
  };

  return (
    <Layout pageTitle="Home" pageDescription="Home Page" progressBar>
      <div className="container pt-sm pb-lg flex flex-col gap-8 md:gap-10 lg:gap-12">
        <LayoutRenderer
          layout={layout}
          mutationToQueryInvalidationMap={{
            [MUTATION_KEYS.UPDATE_FAVORITE]: [LAYOUT_FETCHERS.FAVORITES_SHELF],
          }}
        />
      </div>

      {!user.isAnonymous && !user.isLoggedIn && (
        <Newsletter handleSubmit={handleNewsletterSignup} />
      )}
    </Layout>
  );
};

export const getServerSideProps = withAuthorization(async (ctx) => {
  const serverFetcher = api(ctx);
  const queryClient = new QueryClient();
  const { customHomePageLayoutId: layoutId } = camelizeKeys(ctx.query);

  try {
    const layoutQueryKey = layoutId
      ? [QUERY_KEYS.LAYOUT, layoutId]
      : [QUERY_KEYS.LAYOUT];
    const layout = await queryClient.fetchQuery(layoutQueryKey, () =>
      serverFetcher.getLayout(layoutId),
    );

    await prefetchLayout(layout, serverFetcher, queryClient);
  } catch (e) {
    /* empty */
  }

  return {
    props: {
      dehydratedState: dehydrate(queryClient),
    },
  };
}, ROUTE_PERMISSIONS.PUBLIC);

export default Home;
